export default {
    title: 'Offered Lead Time',
    location: '/business/offered-lead-time',
    tabs: [
        {
            name: 'By Country',
            link: `/business/offered-lead-time`
        },
        {
            name: 'Trend',
            link: `/business/offered-lead-time/:market`
        },
        {
            name: 'Top 10',
            link: '/business/offered-lead-time/top-10'
        }
    ]
}
